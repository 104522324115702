import gql from "graphql-tag";

export const campusQuery = gql`
  query campus($scenarioId: ID!) {
    data(scenarioId: $scenarioId) {
      campuses {
        items {
          id
          code
          name
        }
      }
    }
  }
`;
