import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { Select } from "@foris/avocado-ui";
import { campusQuery } from "../graphql/campus.queries";
import { Campus, DataQuery } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { ISelectorReturn } from "./ISelectors";
import { RegularOption } from "@foris/avocado-ui/lib/types/models/GenericProps";
import css from "./selectors.module.scss";

interface CampusSelectProps extends ISelectorReturn {
  defaultCampus?: Campus;
  isDisabled?: boolean;
}

const getCampusOption = (campus: Campus): RegularOption => {
  if (!campus?.code || !campus?.id) {
    return {
      label: "",
      value: "",
    };
  }

  const campusName = campus?.name ? ` - ${campus?.name}` : "";

  return {
    label: `${campus?.code}${campusName}`,
    value: campus?.id,
  };
};

const CampusSelect = (props: CampusSelectProps) => {
  const client = useApolloClient();
  const { onCallback, label, className, placeholder, defaultCampus, isDisabled = false } = props;
  const { scenario }: IParams = useParams();
  const [optionsCampuses, setOptionsCampuses] = useState([]);
  const [valueCampus, setValueCampus] = useState(
    defaultCampus ? getCampusOption(defaultCampus) : null,
  );

  const variablesCompuses = {
    query: campusQuery,
    variables: {
      scenarioId: scenario,
    },
  };

  const setCampusesOptions = async (data: any) => {
    if (data && data.data) {
      const dataQuery: DataQuery = data.data.data;
      const optCompuses = [];
      if (dataQuery.campuses.items.length > 0) {
        dataQuery.campuses.items.forEach((campus: Campus) => {
          optCompuses.push({ label: `${campus.code} - ${campus.name}`, value: campus.id });
        });
      }
      setOptionsCampuses(optCompuses);
    }
  };

  const getCampusesOptions = async () => {
    try {
      const data = await client.query(variablesCompuses);
      await setCampusesOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCacheCampusesOptions = async () => {
    try {
      const data = await client.readQuery(variablesCompuses);
      await setCampusesOptions(data);
    } catch (error) {
      getCampusesOptions();
    }
  };

  useEffect(() => {
    if (optionsCampuses.length === 0) {
      getCacheCampusesOptions();
    }

    if (defaultCampus?.id) {
      onCallback?.(valueCampus);
    }
  }, []);

  return (
    <div className={cx(css.selectors, className)}>
      {label && <label className="labelItem">Sede</label>}
      <Select
        options={optionsCampuses}
        value={valueCampus}
        placeholder={placeholder ? placeholder : label ? "Seleccionar" : "Sede"}
        isClearable
        isDisabled={isDisabled}
        onChange={(value: RegularOption) => {
          if (value) {
            setValueCampus(value);
            onCallback(value);
          } else {
            setValueCampus(null);
            onCallback(value);
          }
        }}
      />
    </div>
  );
};

export default CampusSelect;
