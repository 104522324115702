import gql from "graphql-tag";
import { FRAGMENT_SECTIONS } from "../../graphql/section.fragment";

export const groupSection = gql`
  query groupSection(
    $scenarioId: ID!
    $originId: ID!
    $resourceType: ResourceType!
    $resourceId: ID!
  ) {
    base {
      customFields(resourceType: $resourceType) {
        id
        code
        name
        label
      }
    }
    cube(scenarioId: $scenarioId, originId: $originId) {
      editorView(resourceType: $resourceType, resourceId: $resourceId) {
        info {
          __typename
          ... on GroupInfo {
            group {
              id
              code
              label
              name
              capacity
              type
              text
              clientCode
              visibleForEnrollment
              term {
                code
                id
                name
                start_date
                end_date
              }
              course {
                code
                name
                curriculum {
                  id
                  name
                  program {
                    id
                    code
                    campus {
                      id
                      code
                      name
                      __typename
                    }
                  }
                }
              }
              customFields {
                id
                value
                code
              }
              links {
                id
                vacancies
                code
                bundle {
                  id
                  code
                  label
                  name
                  capacity
                }
              }
              enrollmentStats {
                id
                enrollmentsCount
                waitingListsCount
              }
              labels {
                id
                code
                name
                category {
                  id
                  code
                  name
                  labelSource {
                    id
                    code
                    name
                  }
                }
              }
              totalBlocks
              totalBlocksWithInstructors
            }
            __typename
          }
        }
        ...CalendarSections
      }
      editorWeeks(resourceType: $resourceType, resourceId: $resourceId) {
        id
        name
        startingDate
        endingDate
        eventCount
        termParts {
          id
          code
          name
          category {
            id
            code
            name
          }
        }
      }
    }
  }
  ${FRAGMENT_SECTIONS}
`;
