import React, { useContext } from "react";
import { DataGridProps } from "@foris/avocado-ui/lib/types/components/DataGrid/DataGrid";
import { AdaptedGroup } from "@modules/sections/GroupsManager/models";
import { Pill } from "@foris/avocado-ui";
import { lensPath, view } from "ramda";
import CapacityInput from "../components/CapacityInput/CapacityInput";
import Donut from "../components/Donut/Donut";
import { Context } from "./../context/GroupsManagerContext";
import { ContextEDH } from "@context/ContextEDH";
import * as mouseflow from "@utils/mouseflow";
import { Types as NavigationTypes } from "./../context/navigation.reducer";
import { Link, useHistory, useParams } from "react-router-dom";
import { IParams } from "@models/IParams";
import cx from "classnames";
import css from "../components/SubgroupsTable/subgroupsTable.module.scss";

const isEditionAllowed = view(lensPath(["isEditable", "allowed"]));

export const useSubgroupsPackagesColumns = () => {
  const { state: outerState } = useContext(ContextEDH);
  const { state, dispatch } = useContext(Context);
  const { origin, scenario, workspace }: IParams = useParams();
  const contextUrl = `${workspace}/${scenario}/${origin}`;
  const history = useHistory();

  const columns: DataGridProps<AdaptedGroup>["columns"] = [
    {
      header: "CRN",
      renderer: (group: AdaptedGroup) => {
        const pathname = `/editor/groups-manager/${contextUrl}/${group?.id}`;

        const onClick = () => {
          dispatch({
            type: NavigationTypes.AddToLocationStack,
            payload: {
              view: `/editor/groups-manager/subgroups/${contextUrl}/${state?.data?.group?.id}`,
              page: state?.data?.groupsPageInfo?.page ?? 1,
            },
          });
          mouseflow.actionTag(
            "action_groups_manager_enrollments",
            outerState?.base?.isMouseflowEnabled,
          );
          history.push(pathname);
        };

        return (
          <Link
            to={{ pathname }}
            className={cx(css.crnLabel, !group?.code && css.linkDisabled)}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              if (group.code) {
                onClick();
              }
            }}
          >
            <p>{group?.code ?? "--"}</p>
          </Link>
        );
      },
      styles: {
        width: "20%",
        maxWidth: "78px",
      },
    },
    {
      header: "Tipo de cupo",
      renderer: (group: AdaptedGroup) => {
        const isPackageType = group.referentType === "PACKAGE";

        return <Pill>{isPackageType ? "" : "No_"}Paquete</Pill>;
      },
      styles: {
        width: "96px",
      },
    },
    {
      header: "Cupo",
      renderer: (group: AdaptedGroup) => (
        <div className={css.capacityInputCol}>
          <CapacityInput
            group={group}
            disabled={!isEditionAllowed(group)}
            isSubgroup={true}
            inputClassname={css.capacityInput}
            disableErrorIcons
          />
        </div>
      ),
      styles: {
        width: "25%",
        maxWidth: "104px",
      },
    },
    {
      header: "Utilización",
      renderer: (group: AdaptedGroup) => <Donut group={group} padding={"0.75rem 0"} />,
      styles: {
        width: "25%",
        maxWidth: "120px",
      },
    },
  ];

  return [columns];
};
