import React, { useContext, useState, useEffect, useMemo } from "react";
import cx from "classnames";
import dayjs from "dayjs";
import { Calendar, Select, Icon } from "@foris/avocado-ui";
import { BookingContext } from "../../context/BookingContext";
import { Types } from "../../context/search.reducer";
import * as utils from "../../utils";
import css from "./dayRow.module.scss";

interface DayRowProps {
  index: number;
  day: "day" | "date";
}

const DayRow: React.FC<DayRowProps> = (props: DayRowProps) => {
  const { index, day } = props;
  const { state, dispatch } = useContext(BookingContext);

  const dateIndex = useMemo(() => {
    const dates = state?.search?.dateTime || [];
    return dates.findIndex(date => date.index === index);
  }, [index, state?.search?.dateTime]);

  const data = (state?.search?.dateTime || [])?.[dateIndex];

  const { start = [], end = [] } = state?.search?.blockOptions || {};
  const [startValue, setStartValue] = useState(data?.start?.value);
  const [endValue, setEndValue] = useState(data?.end?.value);
  const [calendarValue, setCalendarValue] = useState(data?.day?.value);
  const ERROR_START = "Ingresa una hora de inicio";
  const ERROR_END = "Ingresa una hora de termino";

  const endOptions = useMemo(() => {
    if (!startValue) return [];

    const newEndOptions = [];
    const date = new Date().toDateString();

    end.forEach(option => {
      const selectTime = dayjs(`${date} ${startValue.value}`);
      const optionTime = dayjs(`${date} ${option.value}`);
      if (optionTime.isAfter(selectTime)) {
        newEndOptions.push(option);
      }
    });

    return newEndOptions;
  }, [end, startValue]);

  useEffect(() => {
    const clonedDates = [...state?.search?.dateTime];

    if (calendarValue !== data?.day?.value) {
      clonedDates[dateIndex].day.value = calendarValue;
      clonedDates[dateIndex].day.error = false;
      dispatch({ type: Types.SetDateTime, payload: clonedDates });
    }
    if (startValue !== data?.start?.value) {
      clonedDates[dateIndex].start.value = startValue;
      clonedDates[dateIndex].start.error = false;
      dispatch({ type: Types.SetDateTime, payload: clonedDates });
    }
    if (endValue !== data?.end?.value) {
      clonedDates[dateIndex].end.value = endValue;
      clonedDates[dateIndex].end.error = false;
      dispatch({ type: Types.SetDateTime, payload: clonedDates });
    }
  }, [startValue, endValue, calendarValue]);

  const isHoliday = state?.search?.dateTimeFormatted?.[dateIndex]?.day?.isHoliday;
  const isRecurrentHoliday = !!(
    isHoliday &&
    day === "day" &&
    calendarValue &&
    startValue &&
    endValue
  );
  const isDateHoliday = !!(isHoliday && day === "date" && startValue && endValue);

  return (
    <div
      className={cx(
        css.dayRowContent,
        "container-row",
        "row--between",
        "row_nowrap",
        "row_aling_sm--start",
      )}
    >
      <div className={cx(css.cntForm, "container-row", "col")}>
        {day === "date" && (
          <Calendar
            placeholder="dd.mm.yyyy"
            label={`Día ${index}`}
            error={data?.day?.error ? "Ingresa una fecha" : null}
            className={cx(css.cntForm_calendar, "col_3", "col_sm_12")}
            minDate={new Date()}
            selectedDate={Boolean(data?.day?.value) ? dayjs(data?.day?.value).toDate() : undefined}
            onChange={select => {
              setCalendarValue(dayjs(select).format("YYYY-MM-DD"));
            }}
          />
        )}
        {day === "day" && (
          <Select
            label={`Día ${index}`}
            placeholder="Día"
            error={data?.day?.error ? "Ingresa un día" : null}
            className={cx(css.cntForm_selectTime, "col_3", "col_sm_5")}
            isClearable={false}
            options={utils.format.DAYS_OPTIONS}
            value={utils.format.getSelectableDay(data?.day?.value)}
            onChange={value => {
              setCalendarValue(value?.value);
            }}
          />
        )}
        <section className={cx(css.cntForm_select, "container-row", "col")}>
          <Select
            label="Hora inicio"
            placeholder="hh:mm"
            error={data?.start?.error ? ERROR_START : null}
            className={cx(css.cntForm_time, "col_3", "col_sm_5")}
            isClearable={false}
            options={start}
            value={data?.start?.value}
            isDisabled={isRecurrentHoliday || isDateHoliday}
            onChange={value => {
              setStartValue(value);
              setEndValue(null);
            }}
          />
          <span className={cx(css.cntForm_separator, "col_align--center")}>{` - `}</span>
          <Select
            label="Hora Termino"
            placeholder="hh:mm"
            error={data?.start?.error ? ERROR_END : null}
            className={cx(css.cntForm_time, css.cntForm_time__endDate, "col_3", "col_sm_5")}
            isClearable={false}
            options={endOptions}
            isDisabled={!startValue || isRecurrentHoliday || isDateHoliday}
            value={data?.end?.value}
            onChange={value => {
              setEndValue(value);
            }}
          />

          {(isRecurrentHoliday || isDateHoliday) && (
            <div className={css.cntForm_warning}>
              <Icon icon="alert-triangle-full" size={16} />
            </div>
          )}
        </section>
      </div>
      {state?.search?.dateTime?.length > 1 && (
        <button
          className={cx(css.dayRowContent_remove, "col_align--center", "col_align_sm--start")}
          onClick={() => {
            const newCalendar = [...state?.search?.dateTime];
            const indexFind = newCalendar.findIndex(value => value.index === index);
            if (indexFind >= 0) newCalendar.splice(indexFind, 1);
            newCalendar.forEach((value, i) => {
              value.index = i + 1;
            });
            dispatch({ type: Types.SetDateTime, payload: newCalendar });
          }}
        >
          <Icon icon="minus" size={12} className={cx("iconRemove")} />
        </button>
      )}
    </div>
  );
};

export default DayRow;
