import React, { useEffect, useContext } from "react";
import { append } from "ramda";
import { useParams } from "react-router-dom";
import cx from "classnames";
import dayjs from "dayjs";
import { CardState, Icon } from "@foris/avocado-ui";
import { BookingContext } from "../../../../context/BookingContext";
import { Types } from "../../../../context/search.reducer";
import { IParams } from "@models/IParams";
import DayRow from "../../../../components/DayRow/DayRow";
import Requirements from "../../../../components/Requirements/Requirements";
import Preferences from "../../../../components/Preferences/Preferences";
import useGetOptions from "../../../../hooks/useGetOptions";
import useGetBuilding from "../../../../hooks/useGetBuilding";
import useGetClassroomType from "../../../../hooks/useGetClassroomType";
import useGetEventsCollision from "@modules/booking/hooks/useGetEventsCollision";
import { getHasBlockOptions } from "@modules/booking/utils/blockOptions";
import uuid from "uuid-random";
import { Holiday } from "@models/ISchema";
import { formats } from "@modules/sections/utils";
import css from "./singleEvent.module.scss";
import searchCss from "../search.module.scss";

interface SingleEventProps {
  onCollission?: (hasCollision: boolean) => void;
  selectedHolidays?: Holiday[];
}

const SingleEvent: React.FC<SingleEventProps> = ({ onCollission, selectedHolidays }) => {
  const { state, dispatch } = useContext(BookingContext);
  const { scenario }: IParams = useParams();
  const [data, getBlocksOptions] = useGetOptions();
  const [building, getBuildingsOptions] = useGetBuilding();
  const [classroomTypes, getClassroomOptions] = useGetClassroomType();
  const hasBlockOptions = getHasBlockOptions(state?.search?.blockOptions);

  useGetEventsCollision({ days: state?.search?.dateTime, onCollission });

  useEffect(() => {
    if (!hasBlockOptions && !data?.data && !data?.loading) {
      getBlocksOptions(scenario);
    }

    if (state?.search?.buildingOptions?.length === 0 && !building?.data && !building?.loading) {
      getBuildingsOptions(scenario);
    }

    if (
      state?.search?.classroomTypesOptions?.length === 0 &&
      !classroomTypes?.data &&
      !classroomTypes?.loading
    ) {
      getClassroomOptions(scenario);
    }

    return () => {
      onCollission?.(false);
    };
  }, []);

  return (
    <section className={cx(css.cntSingleEvent, "container-row")}>
      {hasBlockOptions && (
        <>
          <section className={cx(css.cntSingleEvent_divider, "col_12")}>
            {state?.search?.dateTime?.map((days, idx: number) => (
              <DayRow key={uuid()} index={days?.index ?? idx + 1} day="date" />
            ))}
            <button
              onClick={() => {
                dispatch({
                  type: Types.SetDateTime,
                  payload: append(
                    {
                      index: state?.search?.dateTime?.length + 1,
                      recurrence: false,
                      day: {
                        value: dayjs(new Date()).format("YYYY-MM-DD"),
                        error: false,
                      },
                      start: {
                        value: null,
                        error: false,
                      },
                      end: {
                        value: null,
                        error: false,
                      },
                    },
                    state?.search?.dateTime ?? [],
                  ),
                });
              }}
              className={cx(css.cntSingleEvent_btnAdd, "container-row", "row_align--center")}
            >
              <div className={css.iconAdd}>
                <Icon icon="plus" size={14} />
              </div>
              Agregar otro día
            </button>

            {!!selectedHolidays?.length && (
              <CardState
                title={`${selectedHolidays?.length} día${
                  selectedHolidays?.length > 1 ? "s" : ""
                } festivo seleccionado`}
                typeCard="warning"
                className={cx(searchCss.cardWarning, "col_12")}
                classNameTitle={searchCss.cardWarning_title}
              >
                {selectedHolidays?.map(holiday => (
                  <p className={searchCss.cardWarning_text} key={holiday?.code}>
                    El día {formats.formats.dateEsFormat(holiday?.date)} corresponde a{" "}
                    {holiday?.name}.
                  </p>
                ))}
              </CardState>
            )}
          </section>
          <Requirements />
          <Preferences />
        </>
      )}
    </section>
  );
};

export default SingleEvent;
