import React, { useEffect, useContext, useMemo } from "react";
import { append, filter, pipe, prop, sort } from "ramda";
import { useParams } from "react-router-dom";
import cx from "classnames";
import dayjs from "dayjs";
import { IParams } from "@models/IParams";
import { IntervalWeek } from "@modules/booking/context/types";
import { Icon, Weekly, IWeeklyItem, CardState } from "@foris/avocado-ui";
import { BookingContext } from "../../../../context/BookingContext";
import { Types } from "../../../../context/search.reducer";
import DayRow from "../../../../components/DayRow/DayRow";
import Requirements from "../../../../components/Requirements/Requirements";
import Preferences from "../../../../components/Preferences/Preferences";
import useGetOptions from "../../../../hooks/useGetOptions";
import useGetBuilding from "../../../../hooks/useGetBuilding";
import useGetClassroomType from "../../../../hooks/useGetClassroomType";
import useGetIntervals from "../../../../hooks/useIntervals";
import { getHasBlockOptions } from "@modules/booking/utils/blockOptions";
import uuid from "uuid-random";
import { getFormattedDate } from "@modules/sections/utils/formats";
import { Holiday } from "@models/ISchema";
import { formats } from "@modules/sections/utils";
import css from "./recurrentEvent.module.scss";
import searchCss from "../search.module.scss";

interface RecurrentEventProps {
  selectedHolidays?: Holiday[];
  isEditing?: boolean;
  onCollission?: (hasCollision: boolean) => void;
}

const RecurrentEvent: React.FC<RecurrentEventProps> = ({
  isEditing = false,
  onCollission,
  selectedHolidays,
}) => {
  const { state, dispatch } = useContext(BookingContext);
  const { scenario }: IParams = useParams();
  const [data, getBlocksOptions] = useGetOptions();
  const [building, getBuildingsOptions] = useGetBuilding();
  const [classroomTypes, getClassroomOptions] = useGetClassroomType();
  const [intervals, getIntervals] = useGetIntervals();
  const hasBlockOptions = getHasBlockOptions(state?.search?.blockOptions);

  /**
   * Get selected weeks sorted by `startingDate`
   */
  const selectedWeeks = useMemo(
    () =>
      pipe(
        filter<IntervalWeek>(prop("selected")),
        sort((a, b) => {
          const dateA = new Date(a?.startingDate)?.getTime();
          const dateB = new Date(b?.startingDate)?.getTime();
          return dateA - dateB;
        }),
      )(state?.search?.intervals ?? []),
    [state?.search?.intervals],
  );

  const weeklyItems = useMemo(() => {
    const changedWeeks = (weekId: string) => {
      const currentWeeks = [...state?.search?.intervals];
      const findIndex = currentWeeks?.findIndex(value => value?.id === weekId);
      currentWeeks[findIndex].selected = !currentWeeks[findIndex]?.selected;
      return currentWeeks;
    };

    const dataIntervals: IWeeklyItem[] = state?.search?.intervals?.map(week => {
      const startDate = dayjs(week?.startingDate)?.format("DD.MM.YYYY");
      const endDate = dayjs(week?.endingDate)?.format("DD.MM.YYYY");

      return {
        id: week?.id,
        label: week?.value,
        disabled: false,
        state: week?.selected ? "active" : null,
        tooltip: {
          label: `Sem ${week?.value} ${startDate} - ${endDate}`,
        },
        onClick: value => {
          dispatch({ type: Types.SetIntervals, payload: changedWeeks(value.id) });
        },
      };
    });

    return dataIntervals;
  }, [state?.search?.intervals]);

  const handleResetIntervals = () => {
    const newIntervals = (state?.search?.intervals ?? []).map(interval => ({
      ...interval,
      selected: false,
    }));

    dispatch({ type: Types.SetIntervals, payload: newIntervals });
  };

  useEffect(() => {
    if (!hasBlockOptions && !data?.data && !data?.loading) {
      getBlocksOptions(scenario);
    }

    if (state?.search?.buildingOptions?.length === 0 && !building?.data && !building?.loading) {
      getBuildingsOptions(scenario);
    }

    if (
      state?.search?.classroomTypesOptions?.length === 0 &&
      !classroomTypes?.data &&
      !classroomTypes?.loading
    ) {
      getClassroomOptions(scenario);
    }

    if (!state?.search?.intervals?.length && !intervals?.data) getIntervals(scenario);

    if (!isEditing && state?.search?.intervals?.length) handleResetIntervals();

    return () => {
      onCollission?.(false);
    };
  }, []);

  const holidaysToRender = useMemo(() => {
    const holidays = [];

    selectedWeeks.forEach(selectedWeek => {
      const startDate = getFormattedDate(selectedWeek.startingDate);
      const endDate = getFormattedDate(selectedWeek.endingDate);

      selectedHolidays.forEach(holiday => {
        if (holiday?.date >= startDate && holiday?.date <= endDate) {
          holidays.push({
            ...holiday,
            week: selectedWeek?.value,
          });
        }
      });
    });

    return Object.values(holidays);
  }, [selectedHolidays]);

  return (
    <section className={cx(css.cntSingleEvent, "container-row")}>
      {hasBlockOptions && (
        <>
          <section className={cx(css.cntSingleEvent_divider, "col_12")}>
            {state?.search?.dateTime?.map((days, idx) => (
              <DayRow key={uuid()} index={days?.index ?? idx + 1} day="day" />
            ))}
            <button
              onClick={() => {
                dispatch({
                  type: Types.SetDateTime,
                  payload: append(
                    {
                      index: state?.search?.dateTime?.length + 1,
                      recurrence: true,
                      day: {
                        value: null,
                        error: false,
                      },
                      start: {
                        value: null,
                        error: false,
                      },
                      end: {
                        value: null,
                        error: false,
                      },
                    },
                    state?.search?.dateTime ?? [],
                  ),
                });
              }}
              className={cx(css.cntSingleEvent_btnAdd, "container-row", "row_align--center")}
            >
              <div className={css.iconAdd}>
                <Icon icon="plus" size={14} />
              </div>
              Agregar otro día
            </button>

            {!!state?.search?.intervals?.length && !!weeklyItems.length && (
              <>
                <Weekly className={css.cntSingleEvent_weekly} weeklyItems={weeklyItems} />
                {selectedWeeks[0] && (
                  <section className="container-row">
                    <div className={css.weeklyFooter}>
                      <label className={cx(css.weeklyFooter_label)}>Inicio</label>
                      <p className={cx(css.weeklyFooter_item)}>
                        <span
                          className={cx(css.weeklyFooter_item__week)}
                        >{`Semana ${selectedWeeks[0]?.value}`}</span>
                        {` ${dayjs(selectedWeeks[0]?.startingDate).format("DD.MM.YYYY")} - ${dayjs(
                          selectedWeeks[0]?.endingDate,
                        ).format("DD.MM.YYYY")}`}
                      </p>
                    </div>

                    <div className={css.weeklyFooter}>
                      <label className={cx(css.weeklyFooter_label)}>Fin</label>
                      <p className={cx(css.weeklyFooter_item)}>
                        <span className={cx(css.weeklyFooter_item__week)}>{`Semana ${
                          selectedWeeks[selectedWeeks.length - 1]?.value
                        }`}</span>
                        {` ${dayjs(selectedWeeks[selectedWeeks.length - 1]?.startingDate).format(
                          "DD.MM.YYYY",
                        )} - ${dayjs(selectedWeeks[selectedWeeks.length - 1]?.endingDate).format(
                          "DD.MM.YYYY",
                        )}`}
                      </p>
                    </div>
                  </section>
                )}
              </>
            )}

            {!!holidaysToRender?.length && (
              <CardState
                title={`${holidaysToRender?.length} día${
                  holidaysToRender?.length > 1 ? "s" : ""
                } festivo seleccionado`}
                typeCard="warning"
                className={cx(searchCss.cardWarning, "col_12")}
                classNameTitle={searchCss.cardWarning_title}
              >
                {holidaysToRender?.map(holiday => (
                  <p className={searchCss.cardWarning_text} key={holiday?.code}>
                    <b>Semana {holiday?.week}:</b> El día{" "}
                    {formats.formats.dateEsFormat(holiday?.date)} corresponde a {holiday?.name}.
                  </p>
                ))}
              </CardState>
            )}
          </section>
          <Requirements />
          <Preferences />
        </>
      )}
    </section>
  );
};

export default RecurrentEvent;
