import React from "react";
import { Switch } from "react-router-dom";
import ProtectedRoute from "./protectedRoutes";
import EditorHome from "../../modules/home/Home";
import Dimensions from "./dimensions";
import Editor from "./editor";
import EditorGroupsManager from "./editorGroupsManager";
import Booking from "./booking";

const moduleRoutes = [
  {
    path: "/editor/groups-manager/:subpage?",
    component: EditorGroupsManager,
  },
  {
    path: "/editor/:subpage?",
    component: Editor,
  },
  {
    path: "/scheduler/:subpage?",
    component: Dimensions,
  },
  {
    path: "/booking/:subpage?",
    component: Booking,
  },
  {
    path: "/home/:workspace/:scenario/:origin",
    component: EditorHome,
  },
  {
    path: "/",
    component: EditorHome,
  },
];

const Shell = () => {
  return (
    <Switch>
      {moduleRoutes.map(route => (
        <ProtectedRoute key={route.path} path={route.path} component={route.component} />
      ))}
    </Switch>
  );
};

export default Shell;
