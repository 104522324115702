import React, { useContext, useEffect, useState } from "react";
import { pipe, set, lensProp } from "ramda";
import { Select } from "@foris/avocado-ui";
import cx from "classnames";
import { BookingContext } from "../../context/BookingContext";
import { Types } from "../../context/search.reducer";
import { Types as SavedRequestTypes } from "../../context/savedRequest.reducer";
import ProgramSelect from "../AsyncSelectors/ProgramAsyncSelect";
import css from "./preferences.module.scss";
import { Building, ClassroomType } from "@models/ISchema";

type ClassroomTypeParam = (ClassroomType & { label: string; value: string }) | null;
type BuildingTypeParam = (Building & { label: string; value: string }) | null;

const Preferences = () => {
  const { state, dispatch } = useContext(BookingContext);
  const [savedProgram, setSavedProgram] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  /**
   * If a classroomBooking is been edited, set the building and classroom type
   * as the default values on the selectors.
   */
  useEffect(() => {
    if (state?.search?.currentBooking) {
      const originalBuilding = state?.search?.currentBooking?.classroom?.building;
      const savedBuilding = state?.savedRequest?.bookingPreferences?.building;
      const originalClassroomType = state?.search?.currentBooking?.classroom?.classroomType;
      const savedClassroomType = state?.savedRequest?.bookingPreferences?.classroomType;
      const hasSavedPreferences = !!state?.savedRequest?.bookingPreferences;

      const building = hasSavedPreferences ? savedBuilding : originalBuilding;
      const classroomType = hasSavedPreferences ? savedClassroomType : originalClassroomType;

      const buildingValue = building?.id
        ? { value: building?.id, label: building?.name, ...building }
        : null;
      const classroomTypeValue = classroomType?.id
        ? {
            value: classroomType?.id,
            label: classroomType?.name,
            ...classroomType,
          }
        : null;

      // set building and classroom type
      dispatch({
        type: Types.SetPreferences,
        payload: pipe(
          set(lensProp<any>("building"), buildingValue),
          set(lensProp<any>("classroomType"), classroomTypeValue),
        )(state?.search?.preferences),
      });

      setSavedProgram(state?.savedRequest?.bookingPreferences?.program);

      dispatch({
        type: SavedRequestTypes.setSavedBookingPreferences,
        payload: null,
      });
    }

    setIsMounted(true);
  }, []);

  const handleSelectClassroomType = (classroomType: ClassroomTypeParam) => {
    const preferences = {
      ...state?.search?.preferences,
      classroomType: classroomType?.value ? classroomType : null,
    };

    dispatch({ type: Types.SetPreferences, payload: preferences });
  };

  const handleSelectBuilding = (building: BuildingTypeParam) => {
    const preferences = {
      ...state?.search?.preferences,
      building: building?.value ? building : null,
    };

    dispatch({ type: Types.SetPreferences, payload: preferences });
  };

  return (
    <section className={cx(css.preferencesContent, "container-row", "col_12")}>
      <p className={cx(css.preferencesContent_text, "col_12")}>
        ¿Tiene alguna preferencia para la sala?
      </p>
      <div className="container-col  col_12">
        <Select
          label="Edificio"
          placeholder="Todos los edificios"
          className={cx(css.preferencesContent_select, "col_4", "col_sm_12")}
          isClearable
          options={state?.search?.buildingOptions}
          value={state?.search?.preferences?.building}
          isDisabled={!state?.search?.buildingOptions}
          onChange={handleSelectBuilding}
        />
        <Select
          label="Tipo"
          placeholder="Todos los tipos"
          className={cx(css.preferencesContent_select, "col_4", "col_sm_12")}
          isClearable
          options={state?.search?.classroomTypesOptions}
          value={state?.search?.preferences?.classroomType}
          isDisabled={!state?.search?.classroomTypesOptions}
          onChange={handleSelectClassroomType}
        />

        {isMounted && <ProgramSelect initialValue={savedProgram} />}
      </div>
    </section>
  );
};

export default Preferences;
