import React, { useEffect, useContext, useState } from "react";
import {
  keys,
  map,
  reduce,
  add,
  propOr,
  pipe,
  isEmpty,
  equals,
  toPairs,
  omit,
  set,
  lensProp,
  empty,
  view,
} from "ramda";
import { useParams } from "react-router-dom";
import cx from "classnames";
import Layout from "@common/layout/Layout";
import { IParams } from "@models/IParams";
import { Loading, Snackbar, Tabs } from "@foris/avocado-ui";
import { ContextEDH } from "@context/ContextEDH";
import { Context } from "../../context/GroupsManagerContext";
import { Types as DataTypes } from "../../context/data.reducer";
import { Types as EditionTypes } from "../../context/editions.reducer";
import { Types as TableFiltersTypes } from "../../context/tableFilters.reducer";
import { useGetGroup } from "../../hooks/useGetGroup";
import { useGetCampuses } from "../../hooks/useGetCampuses";
import GroupDetail from "../../components/GroupDetail/GroupDetail";
import SubgroupsCreation from "../../components/SubgroupsCreation/SubgroupsCreation";
import BottomDetail from "../../components/BottomDetail/BottomDetail";
import SubgroupsTable from "../../components/SubgroupsTable/SubgroupsTable";
import useGroupsManagerCrud from "../../hooks/useGroupsManagerCrud";
import { useGetSubgroups } from "../../hooks/useGetSubgroups";
import EnrollmentsTable from "../../components/EnrollmentsTable/EnrollmentsTable";
import useGetGroupEnrollmentsByGroupCode from "../../hooks/useGetGroupEnrollmentsByGroupCode";
import useGetGroupEnrollmentsByGroupId from "../../hooks/useGetGroupEnrollmentsByGroupId";
import { TableFiltersReducerType } from "../../context/types";
import { displayingParentGroup } from "../../utils/displayingParentGroup";
import splitGroupErrors from "../../utils/splitGroupErrors";
import EnrollmentsTableByCrn from "../../components/EnrollmentsTableByCrn/EnrollmentsTableByCrn";
import buildDefaultTableFilters from "../../utils/buildDefaultTableFilters";
import {
  OrderByDirection,
  GroupsManagerSearchOrderByFields,
  GroupsManagerEnrollmentsOrderByFieldsEnum,
} from "@models/ISchema";
import { AdaptedGroup, GroupEdition } from "../../models";
import useGetCapacityErrors from "../../hooks/useGetCapacityErrors";
import css from "./groupManager.module.scss";

const ROWS_PER_PAGE = 20;
const SUBGROUPS_PER_PAGE = 50;
const FORCED_WAITING_TIME = 4000; // ms

const GroupManagerApp: React.FC = () => {
  const { scenario, origin, id: groupId }: IParams = useParams();
  const { state: outerState } = useContext(ContextEDH);
  const { state, dispatch } = useContext(Context);
  const [displaySnackbar, setDisplaySnackbar] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [subgroups, subgroupsRequestLoading, subgroupsPageInfo, getSubgroups] = useGetSubgroups();
  const [{ campuses, isLoading: isLoadingCampuses }, getCampuses] = useGetCampuses();
  const [
    enrollments,
    enrollmentsRequestLoading,
    enrollmentsPageInfo,
    getEnrollments,
  ] = displayingParentGroup()
    ? useGetGroupEnrollmentsByGroupCode()
    : useGetGroupEnrollmentsByGroupId();
  const [displayCreation, setDisplayCreation] = useState(false);
  const [avoidEditionsCleaningAfterRequest, setAvoidEditionsCleaningAfterRequest] = useState(false);
  const [capacitiesSumUp, setCapacitiesSumUp] = useState(
    pipe(map(propOr(0, "capacity")), reduce<any, number>(add, 0))(state?.data?.groups ?? []),
  );
  const [group, getGroup, groupRequestLoading] = useGetGroup();
  const [{ isLoading: isLoadingGroupManagerCrud }, submitEditions] = useGroupsManagerCrud({
    scenario,
    origin,
  });
  const [tableFiltersByTab, setTableFiltersByTab] = useState<{
    campuses: TableFiltersReducerType;
    students: TableFiltersReducerType;
  }>({
    campuses: buildDefaultTableFilters("campuses"),
    students: buildDefaultTableFilters("enrollments"),
  });
  const [activeTab, setActiveTab] = useState(0);
  const [isLoadingOnSave, setIsLoadingOnSave] = useState(false);

  const userAbilities = outerState?.base?.base?.user?.abilities ?? {};
  const isGroupPackageType = view(lensProp("referentType"), group) === "PACKAGE";
  const isGroupParentType = view(lensProp("isReferent"), group) === true;

  // This variable is used to identify if the enrollments tab has already been
  // entered. If not, the `TableFiltersTypes.SetOrderBy` will be setted when the
  // enrollments tab is selected for the first time.
  let enrollmentsTabAlreadyEntered = false;

  const requestEnrollments = (
    page: number,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => {
    if (!group) return;
    dispatch({ type: EditionTypes.Clean });
    getEnrollments(group, { page, size: ROWS_PER_PAGE }, orderBy, searchBy);
  };

  const requestEnrollmentsByGroupId = () => {
    if (enrollmentsRequestLoading) return;

    dispatch({ type: DataTypes.SetGroups, payload: [] });
    requestEnrollments(1, state?.tableFilters?.orderBy, state?.tableFilters?.searchBy);
  };

  const requestSubgroups = (
    page: number,
    avoidEditionsCleaning: boolean,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => {
    if (!group || (group && !isGroupParentType)) return;
    setAvoidEditionsCleaningAfterRequest(avoidEditionsCleaning);
    getSubgroups(group, { page, size: SUBGROUPS_PER_PAGE }, orderBy, searchBy);
  };

  const onCampusesTabSelected = () => {
    setTableFiltersByTab(set(lensProp("students"), state?.tableFilters, tableFiltersByTab));
    if (tableFiltersByTab?.campuses != null) {
      dispatch({
        type: TableFiltersTypes.SetAll,
        payload: tableFiltersByTab?.campuses,
      });
    }
  };

  const onEnrollmentsTabSelected = () => {
    setTableFiltersByTab(set(lensProp("campuses"), state?.tableFilters, tableFiltersByTab));

    // if no enrollments has been requested just yet, do it for the first time
    if (enrollments == null) {
      requestEnrollments(1, undefined, undefined);
    }

    if (!enrollmentsTabAlreadyEntered) {
      dispatch({
        type: TableFiltersTypes.SetOrderBy,
        payload: {
          header: "Estudiante",
          field: GroupsManagerEnrollmentsOrderByFieldsEnum.Student,
          direction: OrderByDirection.Desc,
        },
      });
    }
    if (tableFiltersByTab?.students != null) {
      dispatch({
        type: TableFiltersTypes.SetAll,
        payload: tableFiltersByTab?.students,
      });
    }
    enrollmentsTabAlreadyEntered = true;
  };

  const setDefaultOrderBy = () => {
    dispatch({
      type: TableFiltersTypes.SetOrderBy,
      payload: {
        header: "Sede",
        field: GroupsManagerSearchOrderByFields.Campus,
        direction: OrderByDirection.Desc,
      },
    });
  };

  const onSave = async () => {
    // exclude groups with hard errors
    setIsLoadingOnSave(true);
    const [hardErrorsByGroupId] = splitGroupErrors(state?.editions?.errorsByGroupId);
    const editionsToSubmit: Record<AdaptedGroup["id"], GroupEdition> = omit(
      keys(hardErrorsByGroupId),
      state?.editions?.byGroupId ?? {},
    );

    await submitEditions(editionsToSubmit);

    const onSaveTimeout = setTimeout(() => {
      for (const [groupId, edition] of toPairs(editionsToSubmit)) {
        if (edition?.capacity !== undefined) {
          dispatch({
            type: EditionTypes.AddPendingCapacity,
            payload: { groupId, capacity: edition?.capacity },
          });
        }
      }

      dispatch({ type: EditionTypes.Clean });
      requestSubgroups(
        subgroupsPageInfo?.page ?? 1,
        false,
        state?.tableFilters?.orderBy,
        state?.tableFilters?.searchBy,
      );
      setDisplaySnackbar(true);
      setIsLoadingOnSave(false);
      clearTimeout(onSaveTimeout);
    }, FORCED_WAITING_TIME);
  };

  /**
   * Sum the groups capacities up considering the current editions.
   */
  useEffect(() => {
    const subgroupCapacity = (group: AdaptedGroup) => {
      const byGroupId = state?.editions?.byGroupId;
      const pendingEditionsByGroupId = state?.editions?.pendingCapacitiesByGroupId;
      return (
        pendingEditionsByGroupId[group?.id] ?? byGroupId[group?.id]?.capacity ?? group?.capacity
      );
    };

    // if we're on the enrollments view, set the capacity from the group itself.
    if (isEmpty(state?.data?.groups)) {
      setCapacitiesSumUp(state?.data?.group?.capacity ?? 0);
      return;
    }

    const capacities = map(subgroupCapacity, state?.data?.groups ?? []);
    setCapacitiesSumUp(reduce(add, 0, capacities));
  }, [state?.data?.groups, state?.editions?.byGroupId]);

  useEffect(() => {
    getGroup(scenario, origin, groupId);
    setDefaultOrderBy();
  }, []);

  /**
   * Update the context's `groups` after request
   */
  useEffect(() => {
    if (subgroups === null) return;
    const requestWasActuallyMade =
      !equals(subgroups, state?.data?.groups) &&
      !equals(subgroupsPageInfo, state?.data?.groupsPageInfo);

    if (!avoidEditionsCleaningAfterRequest && requestWasActuallyMade) {
      dispatch({ type: EditionTypes.Clean });
      setAvoidEditionsCleaningAfterRequest(false);
    }

    dispatch({ type: DataTypes.SetGroups, payload: subgroups ?? [] });
    dispatch({ type: DataTypes.SetGroupsById, payload: subgroups ?? [] });
    dispatch({ type: DataTypes.SetGroupsPageInfo, payload: subgroupsPageInfo });
    dispatch({ type: EditionTypes.SanitizePendingEditions, payload: subgroups ?? [] });
  }, [subgroups, subgroupsPageInfo]);

  /**
   * After the enrollments are requested, set loading as false and clean
   * any editions made.
   */
  useEffect(() => {
    if (enrollments === null) return;
    dispatch({ type: EditionTypes.Clean });
  }, [enrollments]);

  useEffect(() => {
    if (!group) return;

    if (isGroupParentType) {
      getCampuses(scenario, origin);
    }

    requestSubgroups(
      subgroupsPageInfo?.page ?? 1,
      false,
      state?.tableFilters?.orderBy,
      state?.tableFilters?.searchBy,
    );

    dispatch({ type: DataTypes.SetGroup, payload: group });

    if (!displayingParentGroup()) {
      requestEnrollmentsByGroupId();
    }
  }, [group]);

  useEffect(() => {
    const shouldRequestEnrollments = activeTab === 1 && !empty(group) && empty(enrollments);
    if (shouldRequestEnrollments) {
      if (displayingParentGroup()) {
        requestSubgroups(1, false, state?.tableFilters?.orderBy, state?.tableFilters?.searchBy);
        // request enrollments by the group's CRN
        requestEnrollments(1, state?.tableFilters?.orderBy, state?.tableFilters?.searchBy);
      } else {
        requestEnrollmentsByGroupId();
      }
    }
  }, [activeTab]);

  /**
   * Build and return the groups report's url.
   */
  const getReportUrl = () => {
    const darwin_url = process.env.REACT_APP_DARWINED_URL;
    const params = `scenario_id=${scenario}&group_id=${state?.data?.group?.id}`;
    return `${darwin_url}groups_manager/enrollments/${origin}?${params}`;
  };

  useEffect(() => {
    setLoading(subgroupsRequestLoading || enrollmentsRequestLoading || groupRequestLoading);
  }, [subgroupsRequestLoading, enrollmentsRequestLoading, groupRequestLoading]);

  const errorsObj = useGetCapacityErrors({
    group: state?.data?.group,
    subGroups: state?.data?.groups,
    editionsByGroupId: state?.editions?.byGroupId,
  });

  const getSubgroupsView = () => {
    return displayCreation ? (
      <SubgroupsCreation
        campuses={campuses ?? []}
        parentGroup={state?.data?.group}
        requestSubgroups={() => {
          requestSubgroups(1, false, state?.tableFilters?.orderBy, state?.tableFilters?.searchBy);
        }}
        setDisplayCreation={setDisplayCreation}
        subgroups={subgroups}
      />
    ) : (
      <>
        <SubgroupsTable
          isGroupPackageType={isGroupPackageType}
          subgroups={subgroups}
          pageInfo={subgroupsPageInfo}
          requestSubgroups={requestSubgroups}
          capacitiesSumUp={capacitiesSumUp}
          setDisplayCreation={setDisplayCreation}
          errorReason={errorsObj.errorKey as any}
          hasHardError={errorsObj.hasHardErrors}
          hasSoftError={errorsObj.hasSoftErrors}
        />
        {Boolean(userAbilities?.can_edit_groups) && (
          <BottomDetail
            onSave={onSave}
            isLoading={isLoadingGroupManagerCrud || isLoadingOnSave}
            externalErrors={errorsObj.errorsByGroupId}
            parentGroupHasError={
              capacitiesSumUp > state?.data?.group?.groupCapacity?.calculatedCapacity
            }
          />
        )}
      </>
    );
  };

  return (
    <Layout contextSearch>
      {loading || isLoadingGroupManagerCrud || isLoadingOnSave || isLoadingCampuses ? (
        <Loading />
      ) : null}
      <div className={cx("container-row", "row--center", css.main)}>
        <Snackbar
          type="warning"
          setValueActive={(value: any) => setDisplaySnackbar(value)}
          active={displaySnackbar}
          icon="circle-full-clock"
          duration={3}
        >
          Solicitud de cambios enviada
        </Snackbar>
        <GroupDetail
          capacitiesSumUp={capacitiesSumUp}
          displayCapacitiesError={
            capacitiesSumUp > state?.data?.group?.groupCapacity?.calculatedCapacity
          }
        />

        {displayingParentGroup() && isGroupPackageType ? (
          <div className={css.tabs}>{getSubgroupsView()}</div>
        ) : null}
        {displayingParentGroup() && !isGroupPackageType ? (
          <Tabs
            className={css.tabs}
            onClick={(idx: number) => {
              setActiveTab(idx);
              if (idx === 0) {
                onCampusesTabSelected();
              } else if (idx === 1) {
                onEnrollmentsTabSelected();
              } else {
                throw new Error("Tab index not supported yet");
              }
            }}
            items={[
              {
                label: `Sedes (${state?.data?.groupsPageInfo?.total ?? 0})`,
                children: getSubgroupsView(),
              },
              {
                label: `Estudiantes (${enrollments?.length ?? 0})`,
                children: (
                  <EnrollmentsTableByCrn
                    enrollments={enrollments ?? []}
                    enrollmentsPageInfo={enrollmentsPageInfo ?? {}}
                    requestEnrollments={(
                      page: number,
                      orderBy: TableFiltersReducerType["orderBy"],
                      searchBy: TableFiltersReducerType["searchBy"],
                    ) => requestEnrollments(page, orderBy, searchBy)}
                    reportUrl={getReportUrl()}
                  />
                ),
              },
            ]}
          />
        ) : null}
        {!displayingParentGroup() && (
          <EnrollmentsTable
            reportUrl={getReportUrl()}
            enrollments={enrollments ?? []}
            enrollmentsPageInfo={enrollmentsPageInfo}
            requestEnrollments={(page: number) =>
              requestEnrollments(page, state?.tableFilters?.orderBy, state?.tableFilters?.searchBy)
            }
          />
        )}
      </div>
    </Layout>
  );
};

export default GroupManagerApp;
