import React, { useEffect, useContext, useState } from "react";
import {
  assoc,
  filter,
  isEmpty,
  keys,
  lensPath,
  lensProp,
  over,
  pipe,
  reduce,
  set,
  T,
  view,
} from "ramda";
import { Link, useParams, useHistory } from "react-router-dom";
import { IParams } from "@models/IParams";
import {
  Button,
  Checkbox,
  DataGrid,
  DonutLegend,
  Icon,
  IconType,
  Tooltip,
} from "@foris/avocado-ui";
import cx from "classnames";
import { ContextEDH } from "@context/ContextEDH";
import * as mouseflow from "@utils/mouseflow";
import Modal from "../Modal/Modal";
import { Context } from "../../context/GroupsManagerContext";
import { TableFiltersReducerType } from "../../context/types";
import Donut from "../Donut/Donut";
import { Types as EditionTypes } from "../../context/editions.reducer";
import { Types as NavigationTypes } from "../../context/navigation.reducer";
import { Types as TableFiltersTypes } from "../../context/tableFilters.reducer";
import CapacityInput from "../CapacityInput/CapacityInput";
import MultiCapacityInput from "../MultiCapacityInput/MultiCapacityInput";
import savingAllowed from "../../utils/savingAllowed";
import donut from "../../utils/donut";
import { PagerProps } from "@foris/avocado-ui/lib/types/components/Pager/Pager";
import { DataGridProps } from "@foris/avocado-ui/lib/types/components/DataGrid/DataGrid";
import { Column, RowAction, SortInfo } from "@foris/avocado-ui/lib/types/components/DataGrid/types";
import subgroupsTableHeaderToOrderByObj from "../../utils/subgroupsTableHeaderToOrderByObj";
import { OrderByDirection, PageInfo } from "@models/ISchema";
import { AdaptedGroup, GroupEditionErrors, SubGroupsTableColumn } from "../../models";
import { useSubgroupsPackagesColumns } from "../../hooks/useSubgroupsPackagesColumns";
import css from "./subgroupsTable.module.scss";
import messageByGroupEditionError from "../../utils/messageByGroupEditionError";

interface Props {
  isGroupPackageType?: boolean;
  subgroups: AdaptedGroup[];
  errorReason?: keyof GroupEditionErrors;
  hasHardError?: boolean;
  hasSoftError?: boolean;
  requestSubgroups: (
    page: number,
    avoidEditionsCleaning: boolean,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => void;
  capacitiesSumUp: number;
  setDisplayCreation: (display: boolean) => void;
  pageInfo: PageInfo;
}

const ROWS_PER_PAGE = 50;

const SubgroupsTable: React.FC<Props> = ({
  isGroupPackageType = false,
  capacitiesSumUp,
  pageInfo,
  errorReason,
  hasHardError,
  hasSoftError,
  requestSubgroups,
  setDisplayCreation,
  subgroups,
}) => {
  const { state: outerState } = useContext(ContextEDH);
  const { state, dispatch } = useContext(Context);
  const history = useHistory();
  const [displayModal, setDisplayModal] = useState(false);
  const [groupIdsToExludeFromCapacityEditions, setGroupIdsToExludeFromCapacityEditions] = useState(
    new Set<string>(),
  );
  const [onModalClick, setOnModalClick] = useState({
    primary: () => {
      /* pass */
    },
    secondary: () => {
      /* pass */
    },
  });
  const [subgroupPackageColumns] = useSubgroupsPackagesColumns();

  const { origin, scenario, workspace }: IParams = useParams();
  const contextUrl = `${workspace}/${scenario}/${origin}`;

  const editionAllowed = view(lensPath(["isEditable", "allowed"]));
  const groupIsVisible = (group: AdaptedGroup): boolean => {
    return state?.editions?.byGroupId[group?.id]?.visibleForEnrollment != undefined
      ? view(lensPath(["byGroupId", group?.id, "visibleForEnrollment"]), state?.editions ?? {})
      : group?.visibleForEnrollment;
  };

  const [leftAction, setLeftAction] = useState<DataGridProps<AdaptedGroup>["leftAction"]>({
    isVisible: T,
    checked: (group: AdaptedGroup) => group?.id in state?.editions?.groupsToEditById,
    disabled: editionAllowed,
    onClick: (group: AdaptedGroup) => {
      if (group?.id in state?.editions?.groupsToEditById) {
        dispatch({ type: EditionTypes.RemoveGroupToEdit, payload: group });
      } else {
        dispatch({ type: EditionTypes.AddGroupToEdit, payload: group });
      }
    },
  });
  const [rightActions, setRightActions] = useState<DataGridProps<AdaptedGroup>["rightActions"]>([
    {
      isVisible: T,
      disabled: (group: AdaptedGroup) => !editionAllowed(group),
      icon: (group: AdaptedGroup): IconType => (groupIsVisible(group) ? "open-eye" : "closed-eye"),
      onClick: (group: AdaptedGroup) => {
        dispatch({
          type: EditionTypes.UpdateGroupVisibility,
          payload: {
            groupId: group?.id,
            value: !groupIsVisible(group),
            originalValue: group?.visibleForEnrollment,
          },
        });
      },
    },
  ]);

  useEffect(() => {
    setLeftAction(
      pipe(
        over(lensProp<DataGridProps<AdaptedGroup>["leftAction"]>("disabled"), editionAllowed),
        set(
          lensProp<DataGridProps<AdaptedGroup>["leftAction"]>("checked"),
          (group: AdaptedGroup) => group?.id in state?.editions?.groupsToEditById,
        ),
        set(
          lensProp<DataGridProps<AdaptedGroup>["leftAction"]>("onClick"),
          (group: AdaptedGroup) => {
            if (group?.id in state?.editions?.groupsToEditById) {
              dispatch({ type: EditionTypes.RemoveGroupToEdit, payload: group });
            } else {
              dispatch({ type: EditionTypes.AddGroupToEdit, payload: group });
            }
          },
        ),
      )(leftAction),
    );
  }, [state?.editions?.groupsToEditById]);

  useEffect(() => {
    const newRightAction = pipe(
      set(
        lensProp<RowAction<AdaptedGroup>>("disabled"),
        (group: AdaptedGroup) => !editionAllowed(group),
      ),
      set(lensProp<RowAction<AdaptedGroup>>("icon"), (group: AdaptedGroup) =>
        groupIsVisible(group) ? "open-eye" : "closed-eye",
      ),
      set(lensProp<RowAction<AdaptedGroup>>("onClick"), (group: AdaptedGroup) => {
        dispatch({
          type: EditionTypes.UpdateGroupVisibility,
          payload: {
            groupId: group?.id,
            value: !groupIsVisible(group),
            originalValue: group?.visibleForEnrollment,
          },
        });
      }),
    )(rightActions[0]);
    setRightActions([newRightAction]);
  }, [state?.editions?.groupsToEditById, state?.data?.groupsById, state?.editions?.byGroupId]);

  /**
   * Given a callback, display the Modal component if any edition has been
   * made and the editions *can be saved*, asking for confirmation.
   *
   * If the user confirms, the given callback it's
   * executed, if the user "cancels" the callback isn't executed and the modal
   * disappears.
   */
  const confirmable = (callback: (...args: any[]) => any) => (...args: any[]): any => {
    if (!isEmpty(state?.editions?.byGroupId) && savingAllowed(state?.editions?.errorsByGroupId)) {
      setDisplayModal(true);
      setOnModalClick({
        primary: () => {
          setDisplayModal(false);
        },
        secondary: () => {
          setDisplayModal(false);
          dispatch({ type: EditionTypes.Clean });
          callback(...args);
        },
      });
    } else {
      callback(...args);
    }
  };

  const pagination = pipe(
    set(lensProp<PagerProps>("onChange"), confirmable(requestSubgroups)),
    set(lensProp<PagerProps>("total"), state?.data?.groupsPageInfo?.total),
    set(lensProp<PagerProps>("page"), state?.data?.groupsPageInfo?.page),
    set(lensProp<PagerProps>("size"), ROWS_PER_PAGE),
  )((state?.data?.groupsPageInfo ?? {}) as PagerProps);

  useEffect(() => {
    if (state?.navigation?.forceFetching) {
      dispatch({ type: NavigationTypes.PopFromLocationStack });
    }
  }, [state?.navigation?.forceFetching]);

  const columns: DataGridProps<AdaptedGroup>["columns"] = isGroupPackageType
    ? subgroupPackageColumns
    : [
        {
          header: "CRN",
          renderer: (group: AdaptedGroup) => {
            const pathname = `/editor/groups-manager/${contextUrl}/${group?.id}`;

            const onClick = () => {
              dispatch({
                type: NavigationTypes.AddToLocationStack,
                payload: {
                  view: `/editor/groups-manager/subgroups/${contextUrl}/${state?.data?.group?.id}`,
                  page: state?.data?.groupsPageInfo?.page ?? 1,
                },
              });
              mouseflow.actionTag(
                "action_groups_manager_enrollments",
                outerState?.base?.isMouseflowEnabled,
              );
              history.push(pathname);
            };

            return (
              <Link
                to={{ pathname }}
                className={cx(css.crnLabel, !group?.code && css.linkDisabled)}
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  if (group.code) {
                    onClick();
                  }
                }}
              >
                <p>{group?.code ?? "--"}</p>
              </Link>
            );
          },
          styles: {
            width: "20%",
            maxWidth: "78px",
          },
        },
        {
          header: "Sede",
          renderer: (group: AdaptedGroup) => {
            return (
              <p className={cx(css.campusCodeCol)}>
                {group?.campus?.code} | {group?.campus?.name}
              </p>
            );
          },
          styles: {
            width: "100%",
          },
        },
        {
          header: "Cupos",
          renderer: (group: AdaptedGroup) => (
            <div className={css.capacityInputCol}>
              <CapacityInput
                group={group}
                disabled={!editionAllowed(group)}
                isSubgroup={true}
                inputClassname={css.capacityInput}
              />
            </div>
          ),
          styles: {
            width: "25%",
            maxWidth: "100px",
          },
        },
        {
          header: "Utilización",
          renderer: (group: AdaptedGroup) => <Donut group={group} padding={"0.75rem 0"} />,
          styles: {
            width: "25%",
            maxWidth: "120px",
          },
        },
      ];

  /**
   * Clean context's groupsToEdit when the table is initialized
   */
  useEffect(() => {
    dispatch({ type: EditionTypes.SetGroupsToEdit, payload: {} });
  }, []);

  /**
   * Set the `groupIdsToExludeFromCapacityEditions` after groups are requested.
   * Groups are exluded if are not editable.
   */
  useEffect(() => {
    const groupIdsToExclude = reduce(
      (acc, group) => {
        if (!group?.isEditable?.allowed) {
          acc?.add(group?.id);
        }
        return acc;
      },
      new Set<AdaptedGroup["id"]>(),
      state?.data?.groups ?? [],
    );
    setGroupIdsToExludeFromCapacityEditions(groupIdsToExclude);
  }, [state?.data?.groups]);

  const onEditionsCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.checked) {
      dispatch({ type: EditionTypes.SetGroupsToEdit, payload: {} });
    } else {
      const groupsToEditById = pipe(
        filter(editionAllowed),
        reduce((acc, group) => assoc(group?.id, group, acc), {}),
      )(state?.data?.groups);
      dispatch({ type: EditionTypes.SetGroupsToEdit, payload: groupsToEditById });
    }
  };

  const leftHeaderComponent = () => {
    return (
      <div className={css.tableCard__header__left}>
        <label className={css.tableCard__title}>{state?.data?.groupsPageInfo?.total} Grupos</label>
        {!isGroupPackageType && (
          <Button
            className={css.tableCard__header__button}
            onClick={() => setDisplayCreation(true)}
            variant="ghost"
          >
            <Icon className={css.tableCard__icon} icon="edit" />
            <span>Editar</span>
          </Button>
        )}
      </div>
    );
  };

  const middleHeaderComponent = () => {
    return (
      <div className={css.tableCard__header__middle}>
        <Button
          onClick={() =>
            requestSubgroups(
              state?.data?.groupsPageInfo?.page,
              true,
              state?.tableFilters?.orderBy,
              state?.tableFilters?.searchBy,
            )
          }
          variant="outline"
        >
          <Icon className={css.tableCard__icon} icon="repeat" />
          <span>Actualizar vista</span>
        </Button>
      </div>
    );
  };

  const leftInfo = () => {
    return (
      <div className={css.tableCard_info_left}>
        <Checkbox
          className={css.tableCard_info_left_checkbox}
          labelRight={`${keys(state?.editions?.groupsToEditById)?.length ??
            0} Sesiones seleccionadas para editar`}
          onChange={onEditionsCheckboxClick}
        />
        <Button
          variant="ghost"
          disabled={(keys(state?.editions?.groupsToEditById)?.length ?? 0) === 0}
          className={css.tableCard_info_left_button}
          onClick={() => {
            dispatch({
              type: EditionTypes.EditGroupsToEditVisibilities,
              payload: state?.data?.groupsById,
            });
          }}
        >
          <Icon
            className={cx(
              css.tableCard__icon,
              (keys(state?.editions?.groupsToEditById)?.length ?? 0) === 0
                ? css.tableCard__icon_disabled
                : "",
            )}
            icon="open-eye"
          />
          Cambiar visibilidad
        </Button>
        <MultiCapacityInput
          disabled={(keys(state?.editions?.groupsToEditById)?.length ?? 0) === 0}
          getGroupIdsToExclude={groupIdsToExludeFromCapacityEditions}
        />
      </div>
    );
  };

  const rightInfo = () => {
    return <DonutLegend className={css.tableCard_info__donut} data={donut({} as AdaptedGroup)} />;
  };

  const onHeaderColumnClick = (column: Column<AdaptedGroup>) => {
    const currentOrderBy = state?.tableFilters?.orderBy;
    if (column?.header === currentOrderBy?.header) {
      const newDirection =
        currentOrderBy?.direction === OrderByDirection.Asc
          ? OrderByDirection.Desc
          : OrderByDirection.Asc;
      const newOrderBy = set(lensProp("direction"), newDirection, state?.tableFilters?.orderBy);

      dispatch({
        type: TableFiltersTypes.SetOrderBy,
        payload: newOrderBy,
      });
      requestSubgroups(pageInfo?.page, false, newOrderBy, state?.tableFilters?.searchBy);
    } else {
      const newOrderBy = subgroupsTableHeaderToOrderByObj(column?.header as SubGroupsTableColumn);
      dispatch({
        type: TableFiltersTypes.SetOrderBy,
        payload: newOrderBy,
      });
      requestSubgroups(pageInfo?.page, false, newOrderBy, state?.tableFilters?.searchBy);
    }
  };

  return (
    <section className={css.tableContent}>
      <Modal
        typeState="confirm"
        title="Cambios no guardados"
        show={displayModal}
        textButtonPrincipal={"Seguir editando"}
        textButtonSecondary={"Cerrar sin guardar"}
        onClickPrincipal={onModalClick?.primary}
        onClickSecondary={onModalClick?.secondary}
        onClose={onModalClick?.primary}
      >
        Si desea guardar los cambios, presione el botón &quot;Seguir editando&quot;.
      </Modal>
      <DataGrid
        className={css.dataGrid}
        columns={columns}
        batch={subgroups ?? []}
        onHeaderClick={onHeaderColumnClick}
        columnsToHide={state?.tableFilters?.columnsToHide}
        pagination={pagination}
        nonSortableColumns={new Set(["Utilización"])}
        leftAction={leftAction}
        rightActions={rightActions}
        sortInfo={{
          header: state?.tableFilters?.orderBy?.header ?? "",
          direction:
            (state?.tableFilters?.orderBy?.direction?.toLowerCase() as SortInfo["direction"]) ??
            "desc",
        }}
        header={{
          className: css.tableCard__header,
          left: leftHeaderComponent(),
          middle: middleHeaderComponent(),
          right: <div className={css.tableCard__header__right}></div>,
        }}
        info={{
          left: leftInfo(),
          right: rightInfo(),
        }}
        summaryRow={() => (
          <section
            className={cx(
              "col_12",
              css.tableCard_total,
              isGroupPackageType ? css.tableCard_total__short : "",
            )}
          >
            <h3 className={cx(css.tableCard_total_label)}>Cupos total</h3>
            <p className={css.tableCard_total_number}>
              {capacitiesSumUp}

              {errorReason && (
                <Tooltip label={messageByGroupEditionError(errorReason).abbreviated}>
                  <Icon
                    className={cx(
                      css.tooltipIcon,
                      hasHardError ? css.tooltipIcon__error : css.tooltipIcon__alert,
                    )}
                    icon={hasHardError ? "circle-full-error" : "alert-triangle"}
                  />
                </Tooltip>
              )}
            </p>
          </section>
        )}
      />
    </section>
  );
};

export default SubgroupsTable;
