import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { Icon, Tag } from "@foris/avocado-ui";
import cx from "classnames";
import { Label, LabelCategory } from "../../../../../models/ISchema";
import { IContextApp, ContextApp } from "@config/Context/contextApp";
import css from "./tags.module.scss";

interface ITags {
  route?: string;
  labels: Label[];
  addMargin?: boolean;
  canEdit?: boolean;
  sectionTitle?: string;
}

const isSystemTag = (label: Label) => label?.category?.labelSource?.code !== "USER-DEFINED";

const Tags: React.FC<ITags> = (props: ITags) => {
  const { route, labels = [], addMargin, canEdit = true, sectionTitle = "Etiquetas" } = props;
  const context: IContextApp = useContext(ContextApp);
  const userCanEdit =
    context?.user?.permissions?.update && context?.user?.abilities?.can_edit_groups && canEdit;

  const labelCategories = useMemo(() => {
    const categories = {};

    labels?.forEach(label => {
      const category = label?.category?.code;

      if (category in categories) {
        categories[category].list.push(label);
      } else {
        categories[category] = { list: [label], category: label?.category };
      }
    });

    return Object.values(categories) as [{ list: Label[]; category: LabelCategory }];
  }, [labels]);

  return (
    <section className={cx(css.cntEditTag, addMargin && css.cntEditTag__addMargin)}>
      <div className={css.cntEditTag_actions}>
        <span className={css.actionsTitle}>{sectionTitle}</span>

        {userCanEdit ? (
          <Link to={route} className={css.actionsLink}>
            <Icon icon="file-edit" size={12} /> Editar
          </Link>
        ) : null}
      </div>

      <section className={cx(css.cntEditTag_contentTags, "container-row")}>
        {labelCategories.map(item => (
          <div key={item.category.code} className={css.tagCategory}>
            <span className={css.tagCategory_name}>{item.category.name}</span>

            <div className={css.tagCategory_labels}>
              {item.list.map(label => (
                <Tag
                  key={label.id}
                  type="outlined"
                  className={cx(css.tagGroup, isSystemTag(label) && css.tagGroup__system)}
                >
                  {!isSystemTag(label) && <span className={css.tagGroup_code}>{label?.code}</span>}
                  <span className={css.tagGroup_name}>{label?.name}</span>
                </Tag>
              ))}
            </div>
          </div>
        ))}
      </section>
    </section>
  );
};

export default Tags;
