import React from "react";
import { IDetails } from "../IHeaderModule";
import css from "./DetailsItems.module.scss";

interface DetailsItemsProps {
  items: IDetails[];
}

const DetailsItems: React.FC<DetailsItemsProps> = ({ items }) => {
  return (
    <div className={css.items}>
      {items.map((item, index) => (
        <div className={css.items_details} key={index}>
          <span className={css.detailsTitle}>{item.title}</span>
          <span className={css.detailsContent}>{item.content}</span>
        </div>
      ))}
    </div>
  );
};

export default DetailsItems;
