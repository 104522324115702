import * as React from "react";
import ReactModal from "react-modal";
import cx from "classnames";
import css from "./modal.module.scss";
import { Button, Icon } from "@foris/avocado-ui";
import { ButtonColor } from "@foris/avocado-ui/lib/types/components/Button/Button";

export type ModalStateType = "confirm" | "error" | "warning";

export interface IModalState {
  /** type modal */
  typeState: ModalStateType;
  /** title modal */
  title?: string;
  subtitle?: string;
  /** open modal */
  show: boolean;
  /** overwrite className */
  className?: string;
  /** content modal */
  children?: React.ReactNode | string;
  /** text button principal */
  textButtonPrincipal?: string;
  colorButtonPrincipal?: ButtonColor;
  /** action button principal */
  onClickPrincipal?: () => void;
  /** On close button */
  onClose?: () => void;
  /** text button secondary */
  textButtonSecondary?: string;
  colorButtonSecondary?: ButtonColor;
  /** action button secondary */
  onClickSecondary?: () => void;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    border: "0px solid rgb(204, 204, 204)",
    borderRadius: 8,
    overlfow: "auto",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 10,
  },
};

ReactModal.setAppElement("#root");

const Modal: React.FC<IModalState> = props => {
  const {
    typeState,
    title,
    subtitle,
    show,
    className,
    children,
    textButtonPrincipal,
    onClickPrincipal,
    textButtonSecondary,
    onClickSecondary,
    onClose,
    colorButtonPrincipal = "primary",
    colorButtonSecondary = "primary",
  } = props;

  return (
    <ReactModal isOpen={show} onRequestClose={(): void => null} style={customStyles}>
      <article
        className={cx(css.modal, typeState && css[`modal__${typeState}`], className && className)}
      >
        <header className={css.header}>
          {onClose && (
            <Button onClick={onClose} className={css.header_close} variant="ghost">
              <Icon icon="close" size={12} /> Cerrar
            </Button>
          )}
          {title && <h1 className={cx(css.content_title, "col_12")}>{title}</h1>}
        </header>
        <section className={cx(css.content, "container-row")}>
          <div className={css.content_wrapper}>
            {!!subtitle && <h2 className={css.content_subtitle}>{subtitle}</h2>}
            <section className={cx(css.content_children, "col_12")}>{children}</section>
          </div>
          <footer className={cx(css.footer, "container-row", "row--between")}>
            {textButtonSecondary && (
              <Button
                color={colorButtonSecondary}
                variant="outline"
                className={css.footer_btn}
                onClick={() => onClickSecondary && onClickSecondary()}
              >
                {textButtonSecondary}
              </Button>
            )}
            {textButtonPrincipal && (
              <Button
                color={colorButtonPrincipal}
                variant="solid"
                className={cx(css.footer_btn)}
                onClick={() => onClickPrincipal && onClickPrincipal()}
              >
                {textButtonPrincipal}
              </Button>
            )}
          </footer>
        </section>
      </article>
    </ReactModal>
  );
};

export default Modal;
