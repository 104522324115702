import React, { useState, useContext, useRef, useEffect } from "react";
import { Icon } from "@foris/avocado-ui";
import cx from "classnames";
import Details from "./Details/Details";
import { IInfoHeader } from "./IHeaderModule";
import { IContextApp, ContextApp } from "@config/Context/contextApp";
import { ContextEDH } from "@context/ContextEDH";
import * as mouseflow from "@utils/mouseflow";
import SectionMenu from "./SectionMenu";
import css from "./header.module.scss";

const Header: React.FC<IInfoHeader> = (props: IInfoHeader) => {
  const {
    type,
    topTitle,
    title,
    subTitle,
    canEditCourse,
    children,
    moreDetails,
    setHeight,
    dimension,
    renderMenu,
    hasSessions,
    onShowError,
  } = props;
  const [openInfo, setOpenInfo] = useState(false);
  const context: IContextApp = useContext(ContextApp);
  const { state } = useContext(ContextEDH);
  const userCanEdit =
    context?.user?.permissions?.update &&
    context?.user?.abilities?.can_edit_links_and_sections &&
    type === "Sections" &&
    canEditCourse;

  const isStringTopTitle = typeof topTitle === "string";
  const isStringTitle = typeof title === "string";
  const headerDimension = useRef(null);

  useEffect(() => {
    if (headerDimension !== null && headerDimension.current && setHeight) {
      setHeight(headerDimension.current.offsetHeight);
    }
  }, []);

  return (
    <header
      className={cx(
        css.cntHeader,
        moreDetails && moreDetails.length > 0 && css.cntHeader__moreInfo,
        "container-row row--between",
      )}
      ref={headerDimension}
    >
      <div className={cx(css.cntHeader_content, openInfo && css.cntHeader_content__divided)}>
        {dimension ? (
          <div className={css.dimensionWrapper}>
            <h1 className={css.dimensionWrapper_title}>Dimension</h1>
            <h2 className={css.dimensionWrapper_name}>{dimension}</h2>
          </div>
        ) : null}

        <section className={css.infoItem}>
          {isStringTopTitle ? (
            <h2 className={css.infoItem_topTitle}>{topTitle}</h2>
          ) : (
            <>{topTitle}</>
          )}
          {isStringTitle ? <h1 className={css.infoItem_title}>{title}</h1> : <>{title}</>}
          {subTitle && <>{subTitle}</>}
        </section>

        {userCanEdit || renderMenu ? (
          <div className={css.menuWrapper}>
            {renderMenu ? renderMenu() : null}

            {userCanEdit ? (
              <SectionMenu
                hasSessions={hasSessions}
                openEdit={() => props.callbackOpenEdit(true)}
                openDisabledSession={() => props.callbackOpenDisabledSession(true)}
                openChangeComponent={() => props.callbackOpenChangeComponent(true)}
                onDisabledSessionError={() => onShowError?.()}
              />
            ) : null}
          </div>
        ) : null}
      </div>

      <div className={openInfo ? css.detailsWrapper : ""}>
        <Details data={moreDetails} active={openInfo}>
          {children}
        </Details>
      </div>

      {(moreDetails && moreDetails.length > 0) || children ? (
        <div className={cx(css.linkTo, "container-row row--center")}>
          <button
            onClick={() => {
              !openInfo &&
                mouseflow.actionTag("action_open_header", state?.base?.isMouseflowEnabled);
              setOpenInfo(!openInfo);
            }}
            className={cx(css.linkTo_btn, openInfo && css.linkTo_btn__active)}
          >
            <Icon icon="chevron-down" size={16} className={css.icon} />
            {openInfo ? "Ocultar" : "Mostrar"} información adicional
          </button>
        </div>
      ) : null}
    </header>
  );
};

export default Header;
