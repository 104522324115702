import React, { useEffect, useContext, useState } from "react";
import { has, isEmpty, keys, last, lensPath, omit, toPairs } from "ramda";
import { useParams } from "react-router-dom";
import { Loading, Snackbar } from "@foris/avocado-ui";
import cx from "classnames";
import { IParams } from "@models/IParams";
import Layout from "@common/layout/Layout";
import { GroupsManagerSearchOrderByFields, OrderByDirection } from "@models/ISchema";
import splitGroupErrors from "../../utils/splitGroupErrors";
import { ContextEDH } from "@context/ContextEDH";
import { Context } from "../../context/GroupsManagerContext";
import { Types as DataTypes } from "../../context/data.reducer";
import { Types as EditionTypes } from "../../context/editions.reducer";
import { Types as NavigationTypes } from "../../context/navigation.reducer";
import { Types as FilterTypes } from "../../context/filters.reducer";
import { Types as FilterSelectorsTypes } from "../../context/filtersSelectors.reducer";
import { Types as TableFiltersTypes } from "../../context/tableFilters.reducer";
import { TableFiltersReducerType } from "../../context/types";
import { useGetGroups } from "../../hooks/useGetGroups";
import useGroupsManagerCrud from "../../hooks/useGroupsManagerCrud";
import GroupsManagerTable from "../../components/GroupsManagerTable/GroupsManagerTable";
import Filters from "../../components/Filters/Filters";
import BottomDetail from "../../components/BottomDetail/BottomDetail";
import { AdaptedGroup, AdaptedValidationErrorReason, GroupEdition } from "../../models";
import css from "./groupsManager.module.scss";

const FORCED_WAITING_TIME = 4000; // ms

const GroupsManagerApp = () => {
  const { origin, scenario }: IParams = useParams();
  const { state: outerState } = useContext(ContextEDH);
  const { state, dispatch } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [displaySnackbar, setDisplaySnackbar] = useState(false);
  const [groups, groupsPageInfo, getGroups] = useGetGroups();
  const [avoidEditionsCleaningAfterRequest, setAvoidEditionsCleaningAfterRequest] = useState(false);
  const [, submitEditions] = useGroupsManagerCrud({ scenario, origin });
  const somethingWasRequested = (state?.data?.groupsPageInfo?.page ?? 0) > 0;
  const userAbilities = outerState?.base?.base?.user?.abilities ?? {};

  /**
   * Set the default orderBy information
   */
  useEffect(() => {
    dispatch({
      type: TableFiltersTypes.SetOrderBy,
      payload: {
        header: "CRN",
        field: GroupsManagerSearchOrderByFields.GroupCode,
        direction: OrderByDirection.Desc,
      },
    });
  }, []);

  const requestGroups = (
    page: number,
    avoidEditionsCleaning: boolean,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => {
    setLoading(true);
    setAvoidEditionsCleaningAfterRequest(avoidEditionsCleaning);
    getGroups(state?.filters, { page, size: 20 }, orderBy, searchBy);
  };

  const onSave = async () => {
    setLoading(true);

    // exclude groups with hard errors
    const [hardErrorsByGroupId] = splitGroupErrors(state?.editions?.errorsByGroupId);
    const editionsToSubmit: Record<AdaptedGroup["id"], GroupEdition> = omit(
      keys(hardErrorsByGroupId),
      state?.editions?.byGroupId ?? {},
    );

    await submitEditions(editionsToSubmit);

    setTimeout(() => {
      for (const [groupId, edition] of toPairs(editionsToSubmit)) {
        if (edition?.capacity !== undefined) {
          dispatch({
            type: EditionTypes.AddPendingCapacity,
            payload: { groupId, capacity: edition?.capacity },
          });
        }
      }

      dispatch({ type: EditionTypes.Clean });

      requestGroups(
        groupsPageInfo?.page ?? 1,
        false,
        state?.tableFilters?.orderBy,
        state?.tableFilters?.searchBy,
      );
      setDisplaySnackbar(true);
    }, FORCED_WAITING_TIME);
  };

  /**
   * Update the context's `groups` and `groupsById` after request
   */
  useEffect(() => {
    dispatch({ type: DataTypes.SetGroups, payload: groups ?? [] });
    dispatch({ type: DataTypes.SetGroupsById, payload: groups ?? [] });
    dispatch({ type: DataTypes.SetGroupsPageInfo, payload: groupsPageInfo });

    if (!avoidEditionsCleaningAfterRequest) {
      dispatch({ type: EditionTypes.Clean });
      setAvoidEditionsCleaningAfterRequest(false);
    }

    // if the group is editable or the reason why is not editable is different
    // that a pending change request, and it exists on the
    // `pendingCapacitiesByGroupId`, remove it.
    for (const group of groups ?? []) {
      if (
        group?.isEditable?.reason !== AdaptedValidationErrorReason?.PendingChangeRequest &&
        has(group?.id, state?.editions?.pendingCapacitiesByGroupId)
      ) {
        dispatch({
          type: EditionTypes.RemovePendingCapacity,
          payload: group?.id,
        });
      }
    }

    setLoading(false);
  }, [groups, groupsPageInfo]);

  /**
   * Since the `forceFetching` is setted to `true` only when we're coming back from
   * the group's detail page, this value can be used to request the groups automatically
   * when we enter the main groups page. The request will not be triggered if the
   * location stack is emtpy.
   */
  useEffect(() => {
    if (state?.navigation?.forceFetching && !isEmpty(state?.navigation?.locationStack)) {
      dispatch({ type: NavigationTypes.PopFromLocationStack });
      requestGroups(
        last(state?.navigation?.locationStack ?? [])?.page ?? 1,
        false,
        state?.tableFilters?.orderBy,
        state?.tableFilters?.searchBy,
      );
    }
    dispatch({ type: NavigationTypes.SetForceFetching, payload: false });
  }, [state?.navigation?.forceFetching]);

  /**
   * Set `owned` filter as `false` on mounted.
   */
  useEffect(() => {
    // set defautl owned filter as false
    dispatch({
      type: FilterTypes.SetAdvancedFilter,
      payload: { lens: lensPath(["owned"]), value: false },
    });

    // set the group's CRN as the default orderBy
    dispatch({
      type: TableFiltersTypes.SetOrderBy,
      payload: {
        header: "CRN",
        direction: OrderByDirection.Desc,
        field: GroupsManagerSearchOrderByFields.GroupCode,
      },
    });
  }, []);

  return (
    <Layout contextSearch>
      {loading && <Loading />}
      <div className={cx("container-row", "row--center", css.main)}>
        <Snackbar
          type="warning"
          setValueActive={setDisplaySnackbar}
          active={displaySnackbar}
          icon="circle-full-clock"
          duration={3}
        >
          Solicitud de cambio enviada
        </Snackbar>
        <Filters
          requestGroups={({ page, avoidEditionsCleaning, usedFilter }) => {
            dispatch({ type: FilterSelectorsTypes.SetUsedFilterToSearch, payload: usedFilter });
            requestGroups(
              page,
              avoidEditionsCleaning,
              state?.tableFilters?.orderBy,
              state?.tableFilters?.searchBy,
            );
          }}
        />
        {somethingWasRequested ? (
          <>
            <GroupsManagerTable
              activeFilterType={state?.filtersSelectors?.usedFilterToSearch}
              requestGroups={(
                page: number,
                avoidEditionsCleaning: boolean,
                orderBy: TableFiltersReducerType["orderBy"],
                searchBy: TableFiltersReducerType["searchBy"],
              ) => {
                requestGroups(page, avoidEditionsCleaning, orderBy, searchBy);
              }}
              pageInfo={groupsPageInfo}
            />
            {Boolean(userAbilities?.can_edit_groups) && <BottomDetail onSave={onSave} />}
          </>
        ) : (
          <></>
        )}
      </div>
    </Layout>
  );
};

export default GroupsManagerApp;
