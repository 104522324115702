import { useState, useContext } from "react";
import { useApolloClient } from "react-apollo";
import { classroomTypesQuery } from "../graphql/classroomType.query";
import { BookingContext } from "../context/BookingContext";
import { Types } from "../context/search.reducer";
import { DataQuery } from "@models/ISchema";

const useGetClassroomType = (): [
  { data: boolean; error: boolean; loading: boolean },
  (scenario: string) => void,
] => {
  const client = useApolloClient();
  const { dispatch } = useContext(BookingContext);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const setClassroomOptions = async (data: any) => {
    if (data) {
      const optCompuses = [];
      if (data.length > 0) {
        data.forEach((classroom: any) => {
          optCompuses.push({ label: classroom.name, value: classroom.id, ...classroom });
        });
      }
      dispatch({ type: Types.SetClassroomTypesOptionsOptions, payload: optCompuses });
      setResult(true);
      setError(false);
      setLoading(false);
    }
  };

  const getClassroomOptions = async (scenario: string) => {
    try {
      const variablesClassRoom = {
        query: classroomTypesQuery,
        variables: {
          scenarioId: scenario,
        },
      };
      const { data } = await client.query(variablesClassRoom);
      const dataQuery: DataQuery = data?.data;
      await setClassroomOptions(dataQuery?.classroomTypes?.items);
    } catch (error) {
      console.error("[ClassroomOptions]", error);
      dispatch({ type: Types.SetClassroomTypesOptionsOptions, payload: [] });
      setResult(true);
      setError(true);
      setLoading(false);
    }
  };

  const getCacheClassroomOptions = async (scenario: string) => {
    try {
      const variablesClassRoom = {
        query: classroomTypesQuery,
        variables: {
          scenarioId: scenario,
        },
      };
      const { data } = await client.readQuery(variablesClassRoom);
      const dataQuery: DataQuery = data?.data;
      await setClassroomOptions(dataQuery?.classroomTypes?.items);
    } catch (error) {
      getClassroomOptions(scenario);
    }
  };

  return [{ data: result, error, loading }, getCacheClassroomOptions];
};

export default useGetClassroomType;
